import React, { KeyboardEventHandler, useCallback } from 'react';

import { sendDigestToKindle } from '../../../shared/foreground/utils/kindle';
import Button from './Button';
import Portal from './Popovers/Portal';
import styles from './SendKindleDigest.module.css';

export const SendKindleDigestModal = ({
  setSendKindleDigestModalIsOpen,
}: {
  setSendKindleDigestModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sendKindleDigestCallback = useCallback(async () => {
    await sendDigestToKindle();
  }, []);

  const cancelAction = useCallback(() => {
    setSendKindleDigestModalIsOpen(false);
  }, [setSendKindleDigestModalIsOpen]);

  const keyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target !== event.currentTarget) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (event.key === 'Escape') {
        cancelAction();
      }
    },
    [cancelAction],
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        cancelAction();
      }
    },
    [cancelAction],
  );

  return (
    <Portal id="dialog--send-kindle-digest">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={styles.dialogWrapper} onClick={onClick} tabIndex={0} onKeyDown={keyDown}>
        <div className={styles.dialogBox}>
          <div className={styles.text}>
            <h1 className={styles.title}>Send a digest now?</h1>
            <p className={styles.subtitle}>
              This will send up to 30 unread articles to your Kindle now. If there are no new items,
              nothing will be sent.
            </p>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.cancelButton} onClick={cancelAction} variant="secondary">
              Cancel
            </Button>
            <Button
              className={styles.darkBlueButton}
              onClick={() => {
                sendKindleDigestCallback();
                cancelAction();
              }}
              variant="primary"
            >
              Send Digest
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};
