import React, {
  KeyboardEventHandler,
  useCallback /* useEffect, useMemo, useRef, useState */,
} from 'react';

import { updateState } from '../../../shared/foreground/models';
import { createToast } from '../../../shared/foreground/toasts.platform';
import Button from './Button';
import Portal from './Popovers/Portal';
import styles from './RemoveKindleEmailModal.module.css';

export const RemoveKindleEmailModal = ({
  setRemoveKindleEmailModalIsOpen,
  setEmailInput,
  setKindleEmailIsSet,
  setShowKindleSetupInstructions,
}: {
  setRemoveKindleEmailModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailInput: React.Dispatch<React.SetStateAction<string>>;
  setKindleEmailIsSet: React.Dispatch<React.SetStateAction<boolean>>;
  setShowKindleSetupInstructions: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const removeKindleEmailCallback = useCallback(async () => {
    await updateState(
      (state) => {
        if (state.persistent.integrations.kindle) {
          state.persistent.integrations.kindle.emailToSendDocuments = '';
        }
      },
      {
        eventName: 'remove-kindle-email',
        userInteraction: 'click',
      },
    );
    setEmailInput('');
    setKindleEmailIsSet(false);
    setShowKindleSetupInstructions(true);
    createToast({ content: `Kindle email removed`, category: 'success' });
  }, [setEmailInput, setKindleEmailIsSet, setShowKindleSetupInstructions]);

  const cancelAction = useCallback(() => {
    setRemoveKindleEmailModalIsOpen(false);
  }, [setRemoveKindleEmailModalIsOpen]);

  const keyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target !== event.currentTarget) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (event.key === 'Escape') {
        cancelAction();
      }
    },
    [cancelAction],
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        cancelAction();
      }
    },
    [cancelAction],
  );

  return (
    <Portal id="dialog--send-kindle-digest">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={styles.dialogWrapper} onClick={onClick} tabIndex={0} onKeyDown={keyDown}>
        <div className={styles.dialogBox}>
          <div className={styles.text}>
            <h1 className={styles.title}>Remove your Kindle email address?</h1>
            <p className={styles.subtitle}>
              Removing your email address will disable sending documents to your Kindle device.
            </p>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.cancelButton} onClick={cancelAction} variant="secondary">
              Cancel
            </Button>
            <Button
              className={styles.darkRedButton}
              onClick={() => {
                removeKindleEmailCallback();
                cancelAction();
              }}
              variant="primary"
            >
              <span className={styles.buttonText}>Remove</span>
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};
