import React, { FormEvent, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { globalState, updateState } from '../../../../shared/foreground/models';
import background from '../../../../shared/foreground/portalGates/toBackground';
import { getAutoKindleEmailDelivery } from '../../../../shared/foreground/stateGetters';
import { toggleAutoKindleEmailSend } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import { setIntegrationConnected } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/integrations';
import { queueJob } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import {
  removeRedactedOpenAIApiKey,
  saveRedactedOpenAIApiKey,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/settings';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { DisplayTheme, JobType } from '../../../../shared/types';
import getServerBaseUrl from '../../../../shared/utils/getServerBaseUrl.platform';
import requestWithAuth from '../../../../shared/utils/requestWithAuth.platformIncludingExtension';
import openPopupWindow from '../../utils/openPopupWindow';
import { openURL } from '../../utils/openURL';
import Button from '../Button';
import Checkbox from '../Checkbox';
import KindleExportFrequencyDropdown from '../Dropdown/KindleExportFrequencyDropdown';
import ChevronDownSmall from '../icons/ChevronDownSmallIcon';
import Icon from '../icons/Icon';
import InstapaperDarkModeIcon from '../icons/InstapaperDarkModeIcon';
import LogoDarkModeIcon from '../icons/LogoDarkModeIcon';
import LogoIcon from '../icons/LogoIcon';
import PocketLogoDarkModeIcon from '../icons/PocketIcon';
import SolidCircleCheck from '../icons/SolidCircleCheck';
import OpenAIIcon from '../icons/SolidOpenAIIcon';
import TwitterIcon from '../icons/SolidTwitterIcon';
import { RemoveKindleEmailModal } from '../RemoveKindleEmailModal';
import { RemoveOpenAIApiKeyDialog } from '../RemoveOpenAIApiKeyDialog';
import { SendKindleDigestModal } from '../SendKindleDigestModal';
import Switch from '../Switch';
import { AccountSettingsPage, List, ListItem } from './AccountSettingsPage';
import accountSettingsStyles from './AccountSettingsPage.module.css';
import styles from './IntegrationsPage.module.css';

export const IntegrationsPage = () => {
  return (
    <AccountSettingsPage title="Integrations">
      <Integrations />
    </AccountSettingsPage>
  );
};

const Integrations = () => {
  const instapaperConnected = globalState((s) => s.persistent.integrations?.instapaper?.connected);
  const pocketConnected = globalState((s) => s.persistent.integrations?.pocket?.connected);
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );

  return (
    <List>
      <ListItem
        image={
          isDarkMode ? (
            <LogoDarkModeIcon className={styles.readwiseLogo} />
          ) : (
            <LogoIcon className={styles.readwiseLogo} />
          )
        }
        title="Readwise"
        subtitle="Reader is automatically integrated with your Readwise account"
      >
        <Button className={styles.connectIntegrationButton} disabled variant="secondary">
          Connected
        </Button>
      </ListItem>

      <ListItem
        image={
          isDarkMode ? (
            <InstapaperDarkModeIcon />
          ) : (
            <img
              className={styles.instapaperLogo}
              alt="instapaper logo"
              src="/onboarding/instapaper_logo.png"
            />
          )
        }
        title="Instapaper"
        subtitle={
          instapaperConnected
            ? 'Account connected and syncing'
            : 'Connect to import articles from Instapaper into Reader'
        }
      >
        <Button
          className={styles.connectIntegrationButton}
          variant="secondary"
          onClick={(e: Event) => {
            e.preventDefault();

            if (instapaperConnected) {
              openURL(`${getServerBaseUrl()}/welcome/sync`, '_blank');
              return;
            }

            const popupWindow = openPopupWindow({
              url: `${getServerBaseUrl()}/instapaper_login`,
              title: 'Connect Reader to Instapaper',
              w: 800,
              h: 600,
            });
            window.addEventListener('message', (event) => {
              if (event?.data.accountConnected) {
                setIntegrationConnected('instapaper');
              }
            });
            setInterval(() => {
              popupWindow?.postMessage('checkInstapaperStatus', '*');
            }, 200);
          }}
        >
          {instapaperConnected ? 'Disconnect' : 'Connect'}
        </Button>
      </ListItem>

      <ListItem
        image={
          isDarkMode ? (
            <PocketLogoDarkModeIcon />
          ) : (
            <img className={styles.pocketLogo} alt="pocket logo" src="/onboarding/pocket_logo.png" />
          )
        }
        title="Pocket"
        subtitle={
          pocketConnected
            ? 'Account connected and syncing'
            : 'Connect to import articles from Pocket into Reader'
        }
      >
        <Button
          className={styles.connectIntegrationButton}
          variant="secondary"
          onClick={(e: Event) => {
            e.preventDefault();
            if (pocketConnected) {
              openURL(`${getServerBaseUrl()}/welcome/sync`, '_blank');
              return;
            }

            const popupWindow = openPopupWindow({
              url: `${getServerBaseUrl()}/pocket_auth_start/?isReader=1`,
              title: 'Connect Reader to Pocket',
              w: 800,
              h: 600,
            });
            window.addEventListener('message', (event) => {
              if (event?.data.accountConnected) {
                setIntegrationConnected('pocket');
              }
            });
            setInterval(() => {
              popupWindow?.postMessage('checkPocketStatus', '*');
            }, 200);
          }}
        >
          {pocketConnected ? 'Disconnect' : 'Connect'}
        </Button>
      </ListItem>

      <TwitterIntegration />
      <OpenAIIntegration />
      <KindleIntegration />
    </List>
  );
};

const TwitterIntegration = () => {
  const history = useHistory();
  const [showSettings, setShowSettings] = useState(false);
  const twitterSettings = globalState(useCallback((state) => state.persistent.settings.twitter, []));
  const twitterIntegration = globalState(
    useCallback((state) => state.persistent.integrations?.twitter, []),
  );
  const twitterConnected = twitterIntegration?.connected;

  const updateTwitterSetting = (settingName: string, value: boolean) => {
    updateState(
      (state) => {
        if (!state.persistent.settings.twitter) {
          state.persistent.settings.twitter = {};
        }
        state.persistent.settings.twitter[settingName] = value;
      },
      { eventName: 'update-twitter-settings', userInteraction: 'click' },
    );
  };

  const migrate = async () => {
    await queueJob({
      jobType: JobType.MigrateTwitterThreads,
      jobArguments: {},
      options: { userInteraction: 'click' },
    });
    background.pollLatestState(20);
    createToast({ content: `Migrating Twitter threads`, category: 'success' });
    history.push('/library');
  };

  return (
    <div className={`${accountSettingsStyles.listItem} ${styles.twitterIntegration}`}>
      <div className={styles.twitterData}>
        <div className={accountSettingsStyles.left}>
          <div className={accountSettingsStyles.imageWrapper}>
            <TwitterIcon className={styles.twitterLogo} />
          </div>
          <div className={accountSettingsStyles.textWrapper}>
            <div className={accountSettingsStyles.title}>
              <h1>Twitter</h1>
            </div>
            <div className={accountSettingsStyles.subtitle}>
              {twitterConnected
                ? 'Account connected'
                : 'Connect to save tweets, threads, and list updates directly to Reader'}
            </div>
          </div>
        </div>

        <div className={accountSettingsStyles.right}>
          {twitterConnected && (
            <Button
              variant="primary"
              className={styles.twitterSettingsButton}
              onClick={() => setShowSettings(!showSettings)}
            >
              Settings
            </Button>
          )}
          <Button
            className={styles.connectIntegrationButton}
            variant="secondary"
            onClick={(e: Event) => {
              e.preventDefault();

              if (twitterConnected) {
                openURL(`${getServerBaseUrl()}/welcome/sync`, '_blank');
                return;
              }

              const popupWindow = openPopupWindow({
                url: `${getServerBaseUrl()}/twitter_start`,
                title: 'Connect Reader to Twitter',
                w: 800,
                h: 600,
              });
              window.addEventListener('message', (event) => {
                if (event?.data.accountConnected) {
                  setIntegrationConnected('twitter');
                }
              });
              setInterval(() => {
                popupWindow?.postMessage('checkTwitterStatus', '*');
              }, 200);
            }}
          >
            {twitterConnected ? 'Disconnect' : 'Connect'}
          </Button>
        </div>
      </div>

      {showSettings && (
        <div className={styles.twitterSettingsWrapper}>
          <div className={styles.twitterSettings}>
            <div className={styles.settings}>
              <h2>Reader settings</h2>
              <div className={styles.option}>
                <Checkbox
                  ariaLabel="Save single tweets"
                  labelId="rdr-twitter-single"
                  isChecked={Boolean(twitterSettings?.saveSingleTweetsInReader)}
                  onCheckedChange={() =>
                    updateTwitterSetting(
                      'saveSingleTweetsInReader',
                      !twitterSettings?.saveSingleTweetsInReader,
                    )
                  }
                />
                <label id="rdr-twitter-single" htmlFor="rdr-twitter-single">
                  Save single tweets
                </label>
              </div>

              <div className={styles.option}>
                <Checkbox
                  ariaLabel="Save Twitter threads"
                  labelId="rdr-twitter-threads"
                  isChecked={Boolean(twitterSettings?.saveThreadsInReader)}
                  onCheckedChange={() =>
                    updateTwitterSetting('saveThreadsInReader', !twitterSettings?.saveThreadsInReader)
                  }
                />
                <label htmlFor="rdr-twitter-threads">Save Twitter threads</label>
              </div>
            </div>

            <div className={styles.settings}>
              <h2>Readwise settings</h2>
              <div className={styles.option}>
                <Checkbox
                  ariaLabel="Save single tweets"
                  labelId="rw-single"
                  isChecked={Boolean(twitterSettings?.saveSingleTweetsInReadwise)}
                  onCheckedChange={() =>
                    updateTwitterSetting(
                      'saveSingleTweetsInReadwise',
                      !twitterSettings?.saveSingleTweetsInReadwise,
                    )
                  }
                />
                <label htmlFor="rw-single">Save single tweets</label>
              </div>

              <div className={styles.option}>
                <Checkbox
                  ariaLabel="Save Twitter threads"
                  labelId="rw-twitter-threads"
                  isChecked={Boolean(twitterSettings?.saveThreadsInReadwise)}
                  onCheckedChange={() =>
                    updateTwitterSetting(
                      'saveThreadsInReadwise',
                      !twitterSettings?.saveThreadsInReadwise,
                    )
                  }
                />
                <label htmlFor="rw-twitter-threads">Save Twitter threads</label>
              </div>
            </div>
          </div>
          <Button onClick={migrate} variant="secondary" className={styles.migrateButton}>
            Migrate threads from Readwise
          </Button>
        </div>
      )}
    </div>
  );
};

const OpenAIIntegration = () => {
  const [showSettings, setShowSettings] = useState(false);
  const openaiSettings = globalState(useCallback((state) => state.persistent.settings.openai, []));
  const [openaiApiKey, setOpenaiApiKey] = useState(openaiSettings?.apiKey || '');
  const hasKey = Boolean(openaiSettings?.apiKey?.length);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showVerifyingKey, setShowVerifyingKey] = useState(false);
  const [showKeyIsValid, setShowKeyIsValid] = useState(false);
  const [showInvalidKeyError, setShowInvalidKeyError] = useState(false);

  const mask = (key: string) => key.slice(-4).padStart(key.length, '•');

  const saveKey = async (e: FormEvent<HTMLFormElement> | undefined) => {
    if (e != null) {
      e.preventDefault();
    }

    if (openaiApiKey.length === 0) {
      return;
    }

    try {
      setShowVerifyingKey(true);
      await requestWithAuth(`${getServerBaseUrl()}/reader/api/openai_api_key/`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          token: openaiApiKey,
        }),
      });

      setShowKeyIsValid(true);
      setShowInvalidKeyError(false);
      setTimeout(async () => {
        await saveRedactedOpenAIApiKey(openaiApiKey);
        setOpenaiApiKey(mask(openaiApiKey));
        setShowVerifyingKey(false);
        setShowKeyIsValid(false);
      }, 500);
    } catch {
      setShowInvalidKeyError(true);
      setShowVerifyingKey(false);
      setShowKeyIsValid(false);
    }
  };

  const removeKey = async () => {
    const currentOpenaiApiKey = openaiApiKey;
    setShowRemoveDialog(false);
    setOpenaiApiKey('');

    try {
      await removeRedactedOpenAIApiKey();
      await requestWithAuth(`${getServerBaseUrl()}/reader/api/openai_api_key/`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          token: null,
        }),
      });
    } catch {
      setOpenaiApiKey(currentOpenaiApiKey);
    }
  };

  const isValidKey = (value: string | undefined) => Boolean(value?.length);

  const iconClassNames = `${styles.icon} ${showSettings && styles.iconOpen}`;
  const saveClassNames = `${styles.saveIntegrationKeyButton} ${hasKey && styles.removeIntegrationKey} ${
    showVerifyingKey && styles.openaiKeyButtonVerifying
  }`;

  return (
    <div className={`${accountSettingsStyles.listItem} ${styles.expandableIntegration}`}>
      <div className={styles.expandableIntegrationData}>
        <div className={accountSettingsStyles.left}>
          <div className={accountSettingsStyles.imageWrapper}>
            <OpenAIIcon className={styles.openaiLogo} />
          </div>
          <div className={accountSettingsStyles.textWrapper}>
            <div className={accountSettingsStyles.title}>
              <h1>OpenAI</h1>
            </div>
            <div className={accountSettingsStyles.subtitle}>
              Add your API key to optionally use GPT-4 and more
            </div>
          </div>
        </div>

        <div className={accountSettingsStyles.right}>
          {hasKey && <div className={styles.expandableIntegrationState}>Active</div>}
          <Button
            variant="primary"
            className={styles.expandIntegrationButton}
            onClick={() => setShowSettings(!showSettings)}
          >
            API Key <ChevronDownSmall text="" className={iconClassNames} />
          </Button>
        </div>
      </div>

      {showSettings && (
        <div className={styles.expandableIntegrationWrapper}>
          <div className={styles.expandableIntegrationSettings}>
            <div className={styles.settings}>
              <p>
                Manually saved documents are automatically summarized by GPT-4o mini as part of your
                subscription. Add{' '}
                <a
                  href="https://platform.openai.com/account/api-keys"
                  target="_blank"
                  rel="noreferrer"
                  data-open-on-desktop
                >
                  your OpenAI API key
                </a>{' '}
                to <Link to="preferences/ghostreader">optionally use GPT-4</Link>&nbsp; with Ghostreader
                and enable auto-summarization of Feed documents. Please note that selecting the GPT-4
                model or toggling on auto-summarization will deduct API credits from your Open AI
                account.
              </p>
              <form onSubmit={saveKey}>
                <label htmlFor="api_key">Your OpenAI API Key</label>
                <div>
                  <section className={styles.inputGroup}>
                    <input
                      className={showInvalidKeyError ? styles.hasError : ''}
                      id="api_key"
                      name="api_key"
                      disabled={hasKey}
                      aria-label="OpenAI API key"
                      autoComplete="off"
                      type="text"
                      onChange={(e) => setOpenaiApiKey(e.target.value)}
                      value={openaiApiKey}
                    />
                    {showInvalidKeyError && (
                      <small className={styles.error}>
                        Invalid API Key. Please check the provided key and try again.
                      </small>
                    )}
                  </section>
                  {hasKey ? (
                    <Button
                      className={saveClassNames}
                      variant="secondary"
                      onClick={() => setShowRemoveDialog(true)}
                    >
                      Remove key
                    </Button>
                  ) : (
                    <Button
                      className={saveClassNames}
                      variant="secondary"
                      type="submit"
                      disabled={!isValidKey(openaiApiKey) || showVerifyingKey}
                    >
                      {showVerifyingKey ? 'Verifying' : 'Save key'}{' '}
                      <Verifying isVerified={showKeyIsValid} />
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <RemoveOpenAIApiKeyDialog
        isOpen={showRemoveDialog}
        onConfirm={removeKey}
        onCancel={() => setShowRemoveDialog(false)}
      />
    </div>
  );
};

function Verifying({ isVerified }: { isVerified: boolean }) {
  if (isVerified) {
    return (
      <SolidCircleCheck className={styles.openaiKeyButtonVerification} text="OpenAI API Key is valid" />
    );
  }

  return (
    <Icon text="Verifying OpenAI API Key">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.openaiKeyButtonVerification}
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g className={styles.openaiKeyButtonVerificationIndicator}>
          <ellipse
            cx="10"
            cy="10"
            stroke="#fff"
            strokeOpacity="0.25"
            strokeWidth="3"
            rx="7.31"
            ry="7.31"
          />
          <path
            stroke="url(#paint0_linear_764_42992)"
            strokeLinecap="round"
            strokeWidth="3"
            d="M9.95 2.69a7.31 7.31 0 017.36 7.26"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_764_42992"
            x1="16.311"
            x2="3.689"
            y1="13.69"
            y2="6.311"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset="0.755" stopColor="#fff" stopOpacity="0.01" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  );
}

const KindleIntegration = () => {
  const isAutoKindleEmailDeliveryEnabled = getAutoKindleEmailDelivery();

  const [showSettings, setShowSettings] = useState(false);
  const [sendKindleDigestModalIsOpen, setSendKindleDigestModalIsOpen] = useState(false);
  const [removeKindleEmailModalIsOpen, setRemoveKindleEmailModalIsOpen] = useState(false);

  const kindleSettings = globalState(useCallback((state) => state.persistent.integrations?.kindle, []));
  const [emailInput, setEmailInput] = useState(kindleSettings?.emailToSendDocuments || '');
  const [kindleEmailIsSet, setKindleEmailIsSet] = useState(
    Boolean(kindleSettings?.emailToSendDocuments),
  );
  const [showKindleSetupInstructions, setShowKindleSetupInstructions] = useState(
    !kindleSettings?.emailToSendDocuments,
  );

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!emailInput) {
      return;
    }
    if (!emailInput.endsWith('@kindle.com')) {
      createToast({
        content: `Please, use a valid Kindle email address ending with @kindle.com`,
        category: 'error',
      });
      return;
    }
    await updateState(
      (state) => {
        if (!state.persistent.integrations.kindle) {
          state.persistent.integrations.kindle = {};
        }
        state.persistent.integrations.kindle.emailToSendDocuments = emailInput;
      },
      {
        eventName: 'update-kindle-email',
        userInteraction: 'click',
      },
    );
    setKindleEmailIsSet(true);
    setShowKindleSetupInstructions(false);
    createToast({ content: `Kindle email saved`, category: 'success' });
  };

  const iconClassNames = `${styles.icon} ${showSettings && styles.iconOpen}`;

  return (
    <div className={`${accountSettingsStyles.listItem} ${styles.expandableIntegration}`}>
      <div className={styles.expandableIntegrationData}>
        <div className={accountSettingsStyles.left}>
          <div className={accountSettingsStyles.imageWrapper}>
            <img className={styles.kindleIcon} alt="Kindle Logo" src="/onboarding/kindle.png" />
          </div>
          <div className={accountSettingsStyles.textWrapper}>
            <div className={accountSettingsStyles.title}>
              <h1>Send to Kindle</h1>
            </div>
            <div className={accountSettingsStyles.subtitle}>
              Enable sending documents to be read on your Kindle
            </div>
          </div>
        </div>

        <div className={accountSettingsStyles.right}>
          {kindleSettings?.emailToSendDocuments && (
            <div className={styles.expandableIntegrationState}>Connected</div>
          )}
          <Button
            variant="primary"
            className={styles.expandIntegrationButton}
            onClick={() => setShowSettings(!showSettings)}
          >
            Configure <ChevronDownSmall text="" className={iconClassNames} />
          </Button>
        </div>
      </div>

      {showSettings && (
        <div className={styles.expandableIntegrationWrapper}>
          <div className={styles.expandableIntegrationSettings}>
            <div className={`${styles.settings} ${styles.kindleSettings}`}>
              {showKindleSetupInstructions && (
                <ol className={styles.sendToKindleInstructions}>
                  <li>
                    1. Visit{' '}
                    <a
                      target="_blank"
                      href="https://www.amazon.com/hz/mycd/myx#/home/settings/pdoc#pdoc"
                      rel="noreferrer"
                      data-open-on-desktop="true"
                    >
                      Amazon&apos;s preferences pages
                    </a>
                    <span>, and scroll to &quot;Personal Document Settings.&quot;</span>
                  </li>
                  <li>
                    2. Add <code className={styles.kindleWhitelistAddress}>@readwise.io</code> to the
                    &quot;Approved Personal Document E-mail List.&quot;
                  </li>
                  <li>
                    3. Copy your Kindle&apos;s email address in &quot;Send-to-Kindle E-Mail
                    Settings&quot; and paste it below.
                  </li>
                </ol>
              )}
              <form onSubmit={onSubmit}>
                <label htmlFor="kindle_email">
                  Your Kindle device email (
                  <Button
                    className={styles.kindleSetupInstructionButton}
                    onClick={() => setShowKindleSetupInstructions(!showKindleSetupInstructions)}
                  >
                    setup instructions
                  </Button>
                  )
                </label>
                <div>
                  <section className={styles.inputGroup}>
                    <input
                      required
                      name="kindle_email"
                      aria-labelledby="kindle email"
                      onChange={(e) => setEmailInput(e.target.value)}
                      type="email"
                      autoComplete="off"
                      placeholder="@kindle.com"
                      value={emailInput}
                      disabled={kindleEmailIsSet}
                    />
                  </section>
                  {kindleEmailIsSet ? (
                    <Button
                      className={styles.removeIntegrationKey}
                      variant="secondary"
                      onClick={() => setRemoveKindleEmailModalIsOpen(true)}
                    >
                      Remove Email
                    </Button>
                  ) : (
                    <Button
                      className={styles.saveIntegrationKeyButton}
                      variant="secondary"
                      type="submit"
                      disabled={!emailInput.trim()}
                    >
                      Save
                    </Button>
                  )}
                  {removeKindleEmailModalIsOpen && (
                    <div>
                      <RemoveKindleEmailModal
                        setRemoveKindleEmailModalIsOpen={setRemoveKindleEmailModalIsOpen}
                        setEmailInput={setEmailInput}
                        setKindleEmailIsSet={setKindleEmailIsSet}
                        setShowKindleSetupInstructions={setShowKindleSetupInstructions}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>

            {kindleEmailIsSet && (
              <div className={styles.subSettingsNoBorder}>
                <div className={accountSettingsStyles.textWrapper}>
                  <div className={accountSettingsStyles.title}>
                    <h1>Automatic Delivery</h1>
                  </div>
                  <div className={accountSettingsStyles.subtitle}>
                    Send a digest of up to 30 unread articles to your Kindle
                    <KindleExportFrequencyDropdown />
                  </div>
                  <div className={styles.integrationDescription}>
                    EPUBs, PDFs, and documents in your Feed are not included in the Kindle digest, but
                    can be manually delivered by selecting &quot;Send to Kindle&quot; in the
                    document&apos;s context menu. All digests are sent at midnight Eastern Standard Time
                    and weekly digests are sent on Fridays. If there are no new items, a digest will not
                    be delivered.
                    <Button onClick={() => setSendKindleDigestModalIsOpen(true)}>
                      Send a digest now.
                    </Button>
                  </div>
                  {sendKindleDigestModalIsOpen && (
                    <div>
                      <SendKindleDigestModal
                        setSendKindleDigestModalIsOpen={setSendKindleDigestModalIsOpen}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.right}>
                  <Switch
                    rootProps={{
                      checked: isAutoKindleEmailDeliveryEnabled,
                      onCheckedChange: () => {
                        toggleAutoKindleEmailSend('click');
                      },
                      id: 'auto-kindle-email-send-checkbox',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
